<template>
  <div>
    <PageLoader :storage="appLoading" />
     <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #FFFF">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #FFF">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
 
    <v-layout wrap justify-center pt-3>
      <v-flex xs12 text-left >
        <span class="itemHeading1"> Public Information </span>
      </v-flex>
    </v-layout>
  
    <v-layout wrap justify-start py-4>
      <template v-for="(item, i) in users">
        <v-flex xs12 xl6 pa-2 :key="i">
          <PublicItem v-bind:storage="item" v-bind:isRequired="true" @stepper="winStepper" />
        </v-flex>
      </template>
      <v-flex xs12 v-if="users.length < 1">
        <span class="itemKey"> No data available </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex xs12>
        <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="7"
          color="#919191"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import PublicItem from "./publicItem";
export default {
  components: {
    PublicItem,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    //   users: [],
      pages: 0,
      count: 20,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      addUserDialog: false,
      editUserData: {},
      users:[]
    };
  },
 
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/users/list",
        headers: {
         token: localStorage.getItem("token"),
        },
      
      })
        .then((response) => {
          if (response.data.status == true) {
         {   this.users = response.data.data;
          this.appLoading = false;}

            // this.pages = response.data.pages;
          }
        })
        .catch((err) => {
          this.appLoading = false;
               this.ServerError=true;
          console.log(err);
        });
    },
    winStepper(windowData) {
      if (windowData.type == "Add") {
        if (windowData.getData) this.getData();
        if (!windowData.addUser) this.addUserDialog = false;
      } 
      // else if (windowData.type == "Edit") {
      //   if (windowData.userData) this.editUserData = windowData.userData;
      //   if (windowData.getData) this.getData();
      //   this.editUserDialog = windowData.editUser;
      // } 
      else if (windowData.type == "Suspend") {
        if (windowData.pageResponse.status) {
          this.msg = "User Suspended Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "User not Suspended";
          this.showSnackBar = true;
        }
      }
        else if (windowData.type == "Activate") {
        if (windowData.pageResponse.status) {
          this.msg = "User Activated Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "User not Activated";
          this.showSnackBar = true;
        }
      }
    },
  },
};
</script>